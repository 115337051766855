// var stickyHeader = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = '';
// see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link'];
// see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links

// var feedItemsCarouselBreakpoint = 576;
// see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel

// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false;
// Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// var visualAppealsLoadMore = false;

$(document).ready(function () {
  // Newsletter sign up section
  const newsLetterImage = $("#pageFooterWrapper .NewsletterSign-up-Image");
  const newsLetterContent = $("#pageFooterWrapper .NewsletterSign-up");
  newsLetterContent.appendTo(newsLetterImage);

  // footer section
  const ftrLinks = $("#pageFooterWrapper .Footer-Links");
  const ftrFindUs = $("#pageFooterWrapper .Footer-Findus");
  const ftrFollowUs = $("#pageFooterWrapper .Footer-Followus");
  const ftrLegal = $("#pageFooterWrapper .Footer-Legal");
  ftrLinks.wrapInner('<div class="wrapper"></div>');
  ftrFindUs.wrapInner('<div class="contact"></div>');
  ftrFollowUs.appendTo(ftrFindUs);
  ftrFindUs.appendTo(ftrLinks);
  ftrLegal.appendTo(ftrLinks);

  // Hide newsletter if not on homepage
  $("body:not(.homepage) .NewsletterSign-up-Image").css("display", "none");

  // Move sub banner descriptions out to below with the content
  if ($(".listingIntro").length) {
    $(".listingIntro").prependTo(".pageWrapper > section > .contentBlockWrapper > .contentBlock");
    $(".listingIntro").prependTo(".appealListingPage .donorsListWrapper");
    $(".listingIntro").prependTo(".postCreate > .contentBlockWrapper > .contentBlock");
  }
  
  // Move sub banner title out if carousel multiple is used
  if ($(".carouselUsed").length) {
    $(".carouselUsed .headerText").prependTo(".pageWrapper > section > .contentBlockWrapper > .contentBlock");
    $(".carouselUsed .headerText").prependTo(".appealListingPage .donorsListWrapper");
    $(".carouselUsed .headerText").prependTo(".postCreate > .contentBlockWrapper > .contentBlock");
  }

  // Check if an <img> tag was found
  if (!($("body:not(.homepage) .carouselImageSingle .carouselSlide").find("img").length > 0)) {
    $("body:not(.homepage) .carouselImageSingle").remove();
  }

  // Check if any page title is missing
  const pageBanner = $(
    "body:not(.homepage) .pageWrapper .headerWrapper .headerText"
  );
  if (!(pageBanner.find(".title").length > 0)) {
    let pageTitle = pageBanner
      .find(".breadcrumbWrapper .breadcrumbLink a")
      .text()
      .trim();
    $('<h1 class="title">' + pageTitle + "</h1>").appendTo(pageBanner);
  }

  // Converts unclickable posts as actual unclickables
  $(".listedPost.PostCategory_unclickable").each(function () {
    $(this).find("a").removeAttr("href");
    $(this).find("a").prop("onclick", null).css("cursor", "auto");
    $(this).prop("onclick", null).css("cursor", "auto");
  });

  // share links
  if ($(".shareWrapper").length) {
    // THREADS
    // 1. Get the current page URL
    var currentUrl = window.location.href;
    // 2. Sanitize the URL
    var sanitizedUrl = encodeURIComponent(currentUrl);
    // 3. Create a button element with the sanitized URL
    var threadsButton = $('<a>')
      .text('Threads')
      .attr('id', 'threadsLink')
      .attr('class', 'shareThreads')
      .attr('href', `https://www.threads.net/intent/post?text=${sanitizedUrl}`)
      .attr('target', '_blank')
      .text('Share on Thread');
    // 4. Wrap the <a> element inside an <li> element
    var listItemThreads = $('<li>').append(threadsButton);
    // 5. Append the <li> element to an existing <ul> element
    $('.shareWrapper .shareList').append(listItemThreads);

    // YOUTUBE
    // YouTube channel URL
    var youtubeChannelUrl = "https://www.youtube.com/BTFThyroid";
    // Create an <a> element with the YouTube channel URL
    var channelLink = $('<a>')
      .text('YouTube')
      .attr('id', 'youtubeLink')
      .attr('class', 'youtube')
      .attr('href', youtubeChannelUrl)
      .attr('target', '_blank')
      .text('Visit Our YouTube Channel');

    // Wrap the <a> element inside an <li> element
    var listItemYouTube = $('<li>').append(channelLink);

    // Append the <li> element to an existing <ul> element
    $('.shareWrapper .shareList').append(listItemYouTube);
  }

  // add readmore to cards
  $("#carouselSlides .carouselSlide").each(function () {
    // get the container element inside the current slide
    let container = $(this).find(".carouselSlideSummary");
    // Get the read more element inside the container
    let readmore = container.find(".readMore.carouselSlideReadMore");
    // move the read more outside
    container.after(readmore);
  });

  // Wrap impact stats with inner dev
  $(".homefeaturecategory-homeboximpactstats").wrapInner(
    '<div class="stats-boxed"><div class="stats-wrapper"></div></div>'
  );

  // for the two cta boxes
  const ctaBoxContent = $(".homefeaturecategory-ctaboxcontent");
  const ctaOneContent = $(".homefeaturecategory-ctaonecontent");
  const ctaTwoContent = $(".homefeaturecategory-ctatwocontent");
  ctaBoxContent.wrapInner('<div class="heading"></div>');
  ctaOneContent.appendTo(ctaBoxContent);
  ctaTwoContent.appendTo(ctaBoxContent);

  // Carousel Feed
  if ($(".homeFeed.UserJourneyFeed").length) {
    let prevArrow = '<button type="button" class="prev-slide"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>';
    let nextArrow = '<button type="button" class="next-slide"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>';
    let sliderNav =
      '<div class="slider-nav">' + prevArrow + nextArrow + "</div>";
    $(".homeFeed.UserJourneyFeed").append(sliderNav);
    $(".homeFeed.UserJourneyFeed .feedList").slick({
      fade: true,
      dots: false,
      arrows: true,
      prevArrow: $(".homeFeed.UserJourneyFeed .prev-slide"),
      nextArrow: $(".homeFeed.UserJourneyFeed .next-slide"),
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      adaptiveHeight: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
    // add button to each slide article
    $(".homeFeed.UserJourneyFeed .feedItem").each(function () {
      const href = $(this).children("a").attr("href");
      const carouselDetail = $(this).find(".feedItemDetailsWrapper");

      $(carouselDetail).append(
        `<a href="${href}" class="cta-button-inverse">Read More</a>`
      );
    });
  }

  // ordering sections as per design
  $(".homefeaturecategory-howcanwehelp").wrapInner('<div class="page-title"></div>');
  $(".HowcanwehelpFeed").appendTo(".homefeaturecategory-howcanwehelp");
  $(".homefeaturecategory-userjourney").wrapInner('<div class="page-title"></div>');
  $(".UserJourneyFeed").appendTo(".homefeaturecategory-userjourney");
  $(".homefeaturecategory-joinourevents").wrapInner('<div class="page-title"></div>');
  $(".JoinoureventsFeed").appendTo(".homefeaturecategory-joinourevents");
  $(".homefeaturecategory-newsarticles").wrapInner('<div class="page-title"></div>');
  $(".NewsandarticlesFeed").appendTo(".homefeaturecategory-newsarticles");

  // for related post to click through text and button
  $(".associated:not(.associatedBox) .associatedListing li").each(function() {
    // Find the nearest <a> tag relative to this div
    var nearestAnchor = $(this).find('.associated-image-link');
    // Get the href and title attribute from the nearest <a> tag
    var hrefValue = nearestAnchor.attr('href');
    // Create a new <a> tag with the same href attribute
    var newAnchor = $('<a>').attr('href', hrefValue);
    // Wrap inner content of new <a> around the next element
    $(this).find('p').wrap(newAnchor);
  });

  // for shop posts to click through text
  $("body.shop .listedProduct").each(function() {
    // Find the nearest <a> tag relative to this div
    var nearestAnchor = $(this).find('.listed-post-image-link');
    // Get the href and title attribute from the nearest <a> tag
    var hrefValue = nearestAnchor.attr('href');
    // Create a new <a> tag with the same href attribute
    var newAnchor = $('<a>').attr('href', hrefValue);
    // Wrap inner content of new <a> around the next element
    $(this).find('.postSummary').wrap(newAnchor);
  });
  
  // add view all events button to feed event
  var btnViewEvents = '<div class="view-all"><a class="cta-button-inverse" href="/Events" aria-label="View all events">See all our events</a></div>';
  $(".homeFeed.JoinoureventsFeed").append(btnViewEvents);

  // Callback function to handle intersection
  function handleIntersection(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        $(entry.target).addClass('in-view');
        observer.unobserve(entry.target); // Stop observing once it's in view
      }
    });
  }

  // Options for the Intersection Observer
  const options = {
    root: null, // Use the viewport as the root
    rootMargin: '0px', // No margin
    threshold: 0.5 // 50% of the target is visible
  };

  // Create a new Intersection Observer
  const observer = new IntersectionObserver(handleIntersection, options);

  // Observe each section
  $('.homeIntroWrapper, .homeFeed').each(function() {
    observer.observe(this);
  });

});
